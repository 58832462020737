import { defineStore } from 'pinia';

export const useSliderStore = defineStore('useSliderStore', () => {
  const router = useRouter();
  const homeGenreInitialIndex = ref(0);
  const homeCollectionsInitialIndex = ref(0);
  const browseGenreInitialIndex = ref(0);
  const browseCollectionsInitialIndex = ref(0);

  let prevPosition = import.meta.client ? window.history.state.position - 1 : 0;
  const checkIsNavigatedBackOrFront = () => {
    if (import.meta.server) return false;

    return window.history.state.position === prevPosition;
  };

  router.beforeEach((to, from, next) => {
    prevPosition = window.history.state.position;
    next();
  });

  return {
    homeGenreInitialIndex,
    homeCollectionsInitialIndex,
    browseGenreInitialIndex,
    browseCollectionsInitialIndex,
    checkIsNavigatedBackOrFront,
  };
});
